.date-session .space {

  display: flex;
  margin: 0;
  position: fixed;
  width: 100%;
  height: 100%;
  overflow: hidden;
  top: 0;
  left: 0;
  z-index: 1;
  opacity: 0;
  animation: fadeIn 2s forwards;

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }

  &.fullScreen {
    z-index: 5;
  }


  /* Break animation */
  .break-animation {
    color: #fff;

    button.order {
      background: none;
      background-color: rgba(255, 255, 255, 0.2);
      border: none;
      padding: 15px 0px;
      color: #fff;
      border-radius: 30px;
      width: 100%;
      font-size: 15px;
      font-weight: 900;
      // display: flex;
      display: none; //hiding order button
      justify-content: center;

      .text {
        margin-left: 20px;



        .pre {
          font-size: 10px;
          font-weight: 100;
        }
      }

      .icon {
        position: relative;

        .food {
          position: absolute;
          left: 0;
          top: 0;
          display: none;

        }

        .drinks {
          position: absolute;

          font-size: 40px;
          top: -14px;
          /* font-size: 32px; */
        }


      }
    }

    .on-break-text {
      font-weight: 100;
      font-size: 30px;
      margin: 15px 0;
    }

    .bg {
      animation: slide 3s ease-in-out infinite alternate;
      animation-play-state: running;
      /* Ensure animation is running by default */
      background-image: linear-gradient(-60deg, var(--primary-color) 50%, #e97e57 50%);
      bottom: 0;
      left: -50%;
      opacity: .4;
      position: fixed;
      right: -50%;
      top: 0;
      z-index: 0;
    }

    .bg2 {
      animation-direction: alternate-reverse;
      animation-duration: 4s;
    }

    .bg3 {
      animation-duration: 5s;
    }

    .content {
      box-sizing: border-box;
      left: 50%;
      padding: 10vmin;
      position: fixed;
      text-align: center;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 80vw;
    }

    h1 {
      font-family: monospace;
    }

    @keyframes slide {
      0% {
        transform: translateX(-25%);
      }

      100% {
        transform: translateX(25%);
      }
    }

    .label-text {
      font-size: 12px;
    }

    /* New paused class */
    &.paused {
      .bg {
        animation-play-state: paused !important;
      }
    }

  }



  /*Warning stuff*/

  /* Animation class combining color transition and pulse */

  .warn.end {
    background-color: var(--red-color);
    animation: none !important;
    // animation: 30s linear forwards, pulseFaster 30s linear forwards, pulse 2s infinite;
  }

  .warn {
    animation: colorTransition 30s linear forwards, pulseFaster 30s linear forwards, pulse 3s infinite;
    height: 100vh;
    width: 100vw;
  }

  /* Define the background color transition from orange to red over 30 seconds */
  @keyframes colorTransition {
    0% {
      background-color: var(--yellow-color);
    }

    100% {
      background-color: var(--red-color);
    }
  }

  /* Define the pulsing effect with a slower initial speed */
  @keyframes pulse {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0.5;
    }

    100% {
      opacity: 1;
    }
  }


  /* Adjust pulse animation duration over time */
  @keyframes pulseFaster {
    0% {
      animation-duration: 16s;
    }

    50% {
      animation-duration: 8s;
    }

    100% {
      animation-duration: 4s;
    }
  }

  /* Wrapper class to adjust the pulse animation */






  /* Candle light stuff*/


  $flame-height: 350px;
  $wick-height: 50px;
  $orange: #ff6a00;
  $orange-yellow: #ff9224;
  $dark: #2c2b39;
  $blue-dark: #30537d;
  $blue-light: #76daff;
  $yellow: #fbf348;
  $yellow-grey: #58523a;


  .candle-space {
    background: rgb(66, 48, 40);
    background: radial-gradient(circle, rgba(17, 17, 17, 0.69) 0%, rgba(17, 17, 17, 1) 100%);
    width: 100%;
    height: 100%;
    display: flex;
  }

  .candle {
    width: 34px;
    margin: 30px auto 0 auto;
    position: relative;
    height: 80%;
    align-self: flex-end;
    cursor: pointer;
  }

  .wick {
    position: absolute;
    width: 6px;
    height: $wick-height;
    background: #23161a;
    top: $flame-height - 0.8*$wick-height;
    left: 50%;
    transform: translateX(-50%) skewX(2deg);
    border-radius: 10%;
    box-shadow: 0 0 2px 0px black;
  }

  .wick:before,
  .wick:after {
    content: '';
    position: absolute;
    left: 50%;
    width: 0;
    box-shadow:
      0 -14px 10px 8px white,
      0 -10px 10px 8px rgba(255, 215, 0, 0.7),
      0 -3px 10px 8px rgba(255, 106, 0, 0.7),
      0 6px 3px 4px black;
  }

  .flame {
    width: 20px;
    height: $flame-height;
    margin: 0px auto;
    position: relative;
    transform-origin: 50% 90%;
  }

  .flame.animate {
    animation: move 3s infinite, move-left 3s infinite;
  }

  .flame.animate .top {
    animation: flame-up 4s infinite;
  }

  .flame .top {
    width: 20px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    border-top-left-radius: 500%;
    border-bottom-left-radius: 50px;
    border-top-right-radius: 500%;
    border-bottom-right-radius: 50px;
    transform: skewY(-10deg);
    box-shadow:
      0 0px 0px 3px white,
      0 -20px 1px 4px white,
      0 -25px 2px 3px gold,
      0 -30px 5px 4px $orange,
      0 0px 150px 10px $orange,
      0 -10px 2px 4px white,
      0 -5px 3px 3px white;
  }

  .flame .shadows {
    position: absolute;
    left: 50%;
    top: 0;
    width: 1px;
    height: 60px;
    border-radius: 50%;
    box-shadow:
      0 5px 20px 15px gold,
      0 0px 100px 20px $orange,
      0 15px 50px 15px $orange,
      5px 30px 5px 13px $orange,
      5px 50px 5px 13px $orange,
      0 75px 50px 30px black;
  }

  .flame .bottom {
    transform: scale(0.9);
    position: absolute;
    bottom: 6px;
    left: 9px;
    width: 1px;
    height: 8px;
    border-radius: 1%;
    background: $dark;
    box-shadow:
      inset 0 7px 12px -2px $yellow,
      inset 0 9px 57px -3px rgba(255, 0, 0, 0.4),
      inset 0 -5px 8px 2px black,
      0 0 3px 0px $orange,
      inset 0 -10px 4px 4px rgba(255, 106, 0, 0.5),
      5px -10px 10px 5px red,
      0 -15px 10px 10px gold,
      5px -25px 10px 5px gold,
      0 2px 5px 10px $blue-dark,
      0 -2px 2px 14px $blue-light,
      0 2px 10px 12px $blue-light;
  }

  .wax {
    position: relative;
    top: 15px;
    width: 100%;
    height: 100%;
    background: $orange-yellow;
    background: -moz-linear-gradient(top, $orange-yellow 0px, $orange-yellow 20px, $yellow-grey 50px);
    background: -webkit-linear-gradient(top, $orange-yellow 0px, $orange-yellow 20px, $yellow-grey 50px);
    background: linear-gradient(to bottom, $orange-yellow 0px, $orange-yellow 20px, $yellow-grey 50px);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff9224', endColorstr='#58523a', GradientType=0);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow:
      inset 0 7px 12px -2px $yellow,
      inset 0 9px 57px -3px rgba(255, 0, 0, 0.4),
      inset 0 -5px 8px 2px black,
      0 0 3px 0px $orange;
  }

  @keyframes move {
    0% {
      transform: skewX(2deg) skewY(5deg);
    }

    50% {
      transform: skewX(-2deg) skewY(-0deg);
    }

    100% {
      transform: skewX(2deg) skewY(5deg);
    }
  }

  @keyframes move-left {
    50% {
      transform: skewX(3deg);
    }
  }

  @keyframes flame-up {
    50% {
      box-shadow:
        0 0px 0px 3px white,
        0 -38px 1px 2px white,
        0 -41px 2px 3px gold,
        0 -50px 5px 4px $orange,
        0 0px 150px 10px $orange,
        0 -10px 2px 4px white,
        0 -5px 3px 3px white;
    }
  }


}