.sheets {
    position: fixed;
    z-index: 20;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.1);
    height: 100%;
    width: 100%;
    backdrop-filter: blur(2px);

    .cancelArea {
        height: 10%;
        width: 100%;
    }

    .container {
        position: relative;
        background: rgba(255, 255, 255, 1);
        height: 90%;
        width: 100%;
        // padding: var(--std-padding-around);
        border-radius: 20px 20px 0 0;
        box-shadow: 0px -5px 20px #00000042;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        /* Ensure content does not overflow outside the container */

        .closeBtn {
            position: absolute;
            top: 10px;
            right: 10px;
            color: var(--primary-color);
            z-index: 2;
            padding: 5px;
            border-radius: 5px;
        }

        .closeBtn svg {
            width: 25px;
        }

        .content {
            flex: 1;
            overflow-y: hidden;

        }
    }

    .loader {
        --loader-color: #fff;
    }
}