.events-container {
  padding: var(--std-padding-around);
  background-color: #fff;
  box-sizing: border-box;

  .list {
    display: flex;
    flex-direction: column;

    .loader {
      position: absolute;
      left: calc(50% - 40px);
      top: 50%;
    }
  }

  .item-container {
    border-radius: 10px;
    margin: 5% 0;
    /* padding: 16px; */
    /* box-shadow: 0 0 10px 2px rgb(0 0 0 / 29%); */
    position: relative;
    height: 33vh;
    overflow: hidden;
    background-color: var(--very-light-gray);
  }
  
  .item-container:last-child {
    margin-bottom: var(--nav-bar-height);
  }
  .event-image {
    height: 100%;
    width: 100%;
    filter: sepia(0.1) saturate(2);
    z-index: 0;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  .info-container {
    position: absolute;
    bottom: 0;
    z-index: 1;
    padding: 3%;
    background: linear-gradient(0deg, black, transparent);
    width: 100%;
    box-sizing: border-box;

    .title {
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 4px;
      color: #fff;
    }

    .time-place-age {
      display: grid;
      grid: 1fr / auto auto auto;
      column-gap: 2%;
      justify-content: space-between;
      font-size: 12px;

      svg {
        width: 12px;
        vertical-align: text-top;
        margin-right: 1px;
      }
      .date,
      .hostname,
      .agerange {
        text-wrap: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
      }
      .date {
        color: var(--primary-color);
      }
      .hostname,
      .agerange {
        color: var(--very-light-gray);
      }
    }
  }
}
