.date-session {
  background-color: var(--primary-color-faded);

  position: fixed;
  bottom: 0;
  height: 100%;
  width: 100%;

  margin-top: calc(-1 * env(safe-area-inset-top));

  /* height: calc(100vh -  env(safe-area-inset-bottom) - env(safe-area-inset-top)); */
  padding: var(--std-padding-around);
  box-sizing: border-box;
  // display: flex;
  // flex-direction: column;
  // justify-content: space-between;
  // align-items: center;

  .logo {
    position: relative;
  }

  img.logo-img {
    object-fit: cover;
    object-position: center;
    height: 50px;

    border-radius: 15px;
  }



  .card-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: env(safe-area-inset-top);
    position: fixed;
    top: 0;
    left: 0;
    box-sizing: border-box;
    padding: var(--std-padding-around);
    z-index: 3;
    color: #fff;

    .name {
      line-height: 50px;
      font-weight: 100;
      font-size: 25px;
    }
  }

  .icon {
    width: 25px;
    color: white;
  }

  .report-icon {
    position: absolute;
    top: 0;
    left: 0;
    padding: 10px;
    display: grid;
    grid: auto / 15px 1fr;
    align-items: center;
    text-shadow: 0 0 3px #333;
    color: #fff;
    column-gap: 5px;
    font-size: 12px;
    opacity: 0.8;
    z-index: 1;

    .icon {
      width: 15px;
      color: #fff;
    }
  }

  .close-button {
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
  }



  .next-button-container {
    margin-top: 20px;
  }

  .next-button {
    background-color: var(--yellow-color);
    color: white;
    font-size: 20px;
    line-height: 50px;
    font-weight: bold;
    padding: 10px 20px;
    border: none;

    cursor: pointer;
    width: 104vw;

    position: fixed;
    bottom: 0;
    left: -2%;
    z-index: 2;

    &.glow {
      transition: box-shadow 1s;
      box-shadow: var(--yellow-color);
      animation: glow 1s ease-in-out infinite alternate;
    }

    @keyframes glow {
      0% {
        box-shadow: 0 0 10px;
      }

      100% {
        box-shadow: 0 0 20px;
      }
    }
  }



  .area {
    font-size: 12px;
    color: var(--dark-gray);
    /* display: none; */
  }

  .stars {
    transition: 0.3s;

    &.active {
      height: 30px;
      transform: scale(0.5) translateY(-45px);
    }
  }

  .star {
    cursor: pointer;
    font-size: 50px;
    line-height: 100px;
    color: var(--primary-color);
    opacity: 0.5;
    transition: 0.5s;

    &.selected {
      color: var(--primary-color);
      opacity: 1;
    }


  }
}

/* Browser mode */
@media all and (display-mode: browser) {}