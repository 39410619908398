.splash {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: var(--primary-color);
  display: grid;
  place-items: center;
  place-content: center;
  .logo{
    max-width: 33%;
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }
  
  
}
