/* src/Profile/ProfileCard.scss */


.profile-container {
  /* display: flex;
    flex-direction: column;
    align-items: center; */
  padding: 20px;
  display: grid;
  grid: 1fr 3fr auto auto 1fr 1fr/ 70%;
  gap: 5px;

  text-align: center;
  align-items: center;
  justify-content: center;






  box-sizing: border-box;

  .header {
    color: var(--dark-gray);
  }

  .profile-header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70%;
    margin: auto auto;
  }

  .profile-image {
    width: 100%;
    min-height: 25dvh;
    max-height: 30dvh;
    border-radius: 16px;
    object-fit: cover;
    border: 5px solid #ffffff;
    // box-shadow: 0 0 10px var(--primary-color);
    max-width: 250px;
    border: 1px solid #ececec;

    &.fade {
      opacity: 0.5;
    }
  }


  .edit-button {
    position: absolute;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    border: none;
    padding: 5px 10px;
    border-radius: 0 0 16px 16px;
    cursor: pointer;
    font-size: 18px;
    line-height: 36px;
    font-weight: 600;
    color: #898989;
    width: 100%;
    max-width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 15%;
  }

  .profile-card-name {
    font-size: 24px;
    font-weight: bold;
    margin: 5px 0;
    color: var(--very-dark-gray);
  }

  .profile-phone {
    font-size: 18px;
    color: var(--gray);
    margin: 0;
  }

  textarea.cue-input {
    width: 100%;
    border: 1px solid var(--very-light-gray);
    border-radius: 10px;
    background: none;
    box-sizing: border-box;
    color: var(--very-dark-gray);
    margin-bottom: 5px;
    font-size: 14px;
    padding: 10px;
    text-align: center;
    background-color: #f8f8f8;
  }

  .logout-button {
    background-color: var(--primary-color);
    border: none;
    color: white;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
    min-height: 50px;
    display: grid;
    place-items: center;
    place-content: center;

  }

  .cue-note {
    color: var(--gray);
    margin-bottom: 10px;
    height: 53px;
    font-size: 16px;
    box-sizing: border-box;
    padding: 10px;
    background-color: var(--very-light-gray);
    border-radius: 10px;

  }
}