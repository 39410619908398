/* File: src/components/AbstractChat.scss */

.chat-container {
    display: flex;
    flex-direction: column;
    /* height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom)) ; */
    height: 100%;
    width: 100%;

    margin: 0 auto;
    background-color: #f9f9f9;
    box-sizing: border-box;

    position: relative;

    .back-button {
        color: var(--primary-color);
        width: 30px;
        display: grid;
        place-items: center;
        z-index: 11;
    }

    .chat-header {
        padding: 15px;
        border-bottom: 1px solid #e0e0e0;

        text-align: center;

        font-weight: bold;

        height: 100px;
        box-sizing: border-box;

        background-color: #fff;
        color: var(--dark-gray);
        display: grid;
        grid-template-columns: 0 auto;

        h2 {
            line-height: 90px;
            font-size: 18px;
        }
    }

    .chat-space {
        flex-grow: 6;
    }

    .chat-messages {
        display: flex;
        flex-direction: column;

        padding: 15px;
        overflow-y: auto;
        flex-shrink: 1;

        height: calc(100dvh - 165px);
    }

    .message {
        margin-bottom: 15px;
        max-width: 70%;
    }

    .message-sender {
        font-size: 12px;
        color: #919191;
        margin-bottom: 5px;
    }

    .message-text {
        background-color: #e5e5ea;
        padding: 10px;
        border-radius: 10px;
        word-wrap: break-word;
    }

    .message-timestamp {
        font-size: 12px;
        color: #999;
        margin-top: 5px;
        text-align: right;
    }

    .chat-input {
        display: flex;
        padding: 10px;
        border-top: 1px solid #e0e0e0;
        background-color: #fff;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;

        /* position: fixed;
        bottom: 0;
        width: 100%;
        box-sizing: border-box; */
    }

    .chat-input input {
        flex: 1;
        padding: 10px;
        border: 1px solid #e0e0e0;
        border-radius: 20px;
        margin-right: 10px;
    }

    .chat-input button {
        padding: 10px 20px;
        border: none;
        border-radius: 20px;
        background-color: var(--primary-color);
        color: #fff;
        font-weight: bold;
        cursor: pointer;
    }

    .you {
        align-self: end;

    }

    .you .message-text {
        background-color: var(--primary-color);
        color: var(--background-color)
    }

    .others {
        align-self: start;
    }

}


@media all and (display-mode: browser) {
    .chat-container {
        /* height: calc(100vh - env(safe-area-inset-top) - env(safe-area-inset-bottom) - 80px) ; */
        height: calc(100% + 10px);
    }
}