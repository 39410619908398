.check-in {
  text-align: center;
  height: calc(100dvh);
  padding: 20px;
  display: grid;
  grid: 30px 1fr auto auto / 1fr;
  row-gap: 15px;
  place-content: center;
  box-sizing: border-box;

  .toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .back-btn,
  .help-btn {
    background: none;
    border: none;
    color: var(--primary-color);
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 0;

    .icon {
      width: 20px;
      margin: 0 5px;
    }
  }


  .dater-number {
    position: relative;
    box-sizing: border-box;
    background-color: var(--primary-color);
    border-radius: 5px;
    min-height: 225px;
  }

  .number-display {
    position: absolute;
    width: 100%;
    height: 100%;
    display: grid;
    align-items: center;
    // place-content: center;
    z-index: 1;
    color: var(--very-light-gray);
  }

  .number-display .table-card p {
    margin: 2rem;
    color: var(--very-light-gray);
  }

  .number-display h1.table-number {
    margin: 0;
    color: #fff;
    font-size: 200px;
    line-height: 100%;

    &.ignore {
      opacity: 0.33;
    }
  }

  .logo img {
    position: absolute;
    right: 0;
    bottom: 0;
    overflow: hidden;
    width: 20%;
    opacity: 0.8;
    padding: 5px;
    clip-path: inset(45% 0 0 0);
    z-index: 0;
  }

  .update-button,
  .start-session {

    border: none;

    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .update-button {
    color: var(--primary-color);
    background-color: #fff;
    border: 1px solid var(--primary-color);
  }

  .start-session {
    background-color: var(--primary-color);
    color: #fff;
  }

  .update-button .icon,
  .start-session .icon {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .start-session {
    font-size: 14px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
  }







}

@media screen and (max-height:667px) {
  .check-in {
    .number-display h1.table-number {
      margin: 0;
      color: #fff;
      font-size: 100px;
    }
  }

}