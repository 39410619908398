.join {
    position: fixed;
    z-index: 20;
    top: 0;
    background: rgb(255 255 255 / 10%);
    height: 100%;
    width: 100%;

    .logo {
        width: 100%;
        display: grid;
        place-items: center;
        /* clip-path: inset(35% 0 0 0); */
    }

    img.logo-img {
        width: 33%;
        border-radius: 15%;
        max-width: 150px;
    }

    .cancelArea {
        height: 15%;
    }

    .container {
        position: relative;
        background: rgb(255 255 255 / 90%);
        height: 85%;
        width: 100%;
        padding: var(--std-padding-around);
        border-radius: 20px 20px 0 0;
        box-shadow: 0px -2px 10px #00000042;
        box-sizing: border-box;



        .closeBtn {
            position: absolute;
            right: var(--std-padding-around);
        }

        .closeBtn svg {
            width: 25px;
        }
    }

    .loader {
        --loader-color: #fff;
    }

}

.height-cal-150 {
    height: calc(100% - 150px);
}

.phone,
.otp,
.profile {
    padding: 5px;
    display: flex;
    flex-direction: column;
    height: calc(100% - 150px);
    justify-content: center;
    align-items: center;
    width: 100%;
    box-sizing: border-box;


    .stdInput,
    .upload-photo {
        text-align: center;

        padding: 10px;
        border-radius: 10px;
        border: 1px solid #eee;
        width: 100%;
        margin: 10px;
        box-sizing: border-box;

    }

    .upload-photo {
        position: relative;
        background: var(--very-light-gray);
    }

    button {
        font-size: 24px;
        // width: 100%;
        // border-radius: 10px;
        // border:none;
        // padding: 10px;
        // box-sizing: border-box;
    }

    button.primary {
        margin-top: 10px;
        background-color: var(--primary-color);
        color: var(--background-color);
        display: grid;
        place-content: center;
        height: 50px;
    }

    button.seccondary {
        background-color: var(--background-color);
        color: var(--primary-color);
    }

    .back,
    .custom-file-upload {
        display: block;
        width: 100%;
        background-color: var(--very-light-gray);
        color: var(--dark-gray)
    }

    .hasFile {
        display: none;
    }

    .hasFile.show {
        position: absolute;
        display: block;
        width: 25px;
        left: 20%;

        color: var(--green-color);
    }

    input[type="file"] {
        display: none;
    }


}

.phone {
    svg {
        width: 25px;
        opacity: 0.6;
    }

    .stdInput {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    select {
        background: none;
        border: none;
        padding: 0;
        margin-right: 3px;
        font-size: 24px;
        opacity: 0.6;

    }

    input {
        width: 80%;
        border: none;
        height: 100%;
        font-size: 24px;
        text-align: center;
    }




}

.otp {
    .stdInput {
        font-size: 24px;
    }
}

.profile {

    input,
    select,
    .custom-file-upload {

        font-size: 18px;
    }
}



#captcha {
    position: relative;
    z-index: 0;
    /* Behind everything */
}