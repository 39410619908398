/* QuestionDialog.module.scss */
.question {
    font-size: 18px;
    text-align: center;
    width: 100%;
    height: 100%;
    display: grid;
    place-items: center;
    color: var(--very-dark-gray)
}

.repsoneBtns {
    height: 100%;
    width: 100%;
    display: grid;
    gap: 10px;
    box-sizing: border-box;

}

.repsoneBtns button {
    border: none;
    background: none;
    border-radius: 10px;
    font-size: 18px;

}

.repsoneBtns button.cancel {
    background-color: var(--very-light-gray);
    color: var(--dark-gray);
}

.repsoneBtns button.confirm {
    background-color: var(--primary-color);
    color: #fff;
}

.repsoneBtns button.confirm.in-red {
    background-color: var(--red-color);
}

.repsoneBtns button.confirm.in-yellow {
    background-color: var(--yellow-color);
}