.date-match {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100dvh;

    position: relative;

    .card-container {
        position: absolute;
        width: 80%;
        height: 75%;
        top: 50%;
        left: 50%;

        display: flex;
        align-items: center;
        justify-content: center;
        filter: opacity(0);
        transform: translate(-50%, -50%) scale(0.5);
        transition: 0.3s;

    }

    .card-container:nth-last-child(2) {
        filter: grayscale(1) opacity(0.8) blur(15px);
        ;

        transform: translate(-50%, -50%) scale(0.8);
    }

    .card-container:last-child {
        filter: grayscale(0) opacity(1) drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.2));
        transform: translate(-50%, -50%) scale(1);


    }



    .card {
        height: 100%;
        width: 100%;
        cursor: grab;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 10px;
        background-color: var(--primary-color);
        background-position: center;
        display: grid;
        align-items: end;
        justify-items: center;
        text-shadow: 0 0 5px #333;
        color: #fff;
        box-sizing: border-box;
        padding: 40px 20px;
        font-size: 33px;
    }


    .header-controls {
        position: fixed;
        top: env(safe-area-inset-top);
        color: black;
        right: 0;
        padding: 2%;
        width: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: end;
    }

    button.close {
        color: black;
        background: none;
        border: none;
        width: 50px;
    }

    .button-controls {
        position: absolute;
        bottom: -50px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        gap: 10px;
    }

    .button-controls button {
        padding: 10px;
        font-size: 16px;
        cursor: pointer;
        border: none;
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.8);

        &:hover {
            background-color: #e0e0e0;
        }


    }

    .button-controls .ignore {
        color: gray;
        text-shadow: 0 0 5px #070707;
    }

    .button-controls .like {
        color: var(--primary-color);
    }

    button.goto-matchesBtn {
        background: none;
        border: 2px solid var(--primary-color);
        color: var(--primary-color);
        font-size: 24px;
        padding: 7px;
        border-radius: 10px;
        display: flex;

        svg {
            width: 28px;
            margin-right: 5px;
        }
    }

    .report-icon {
        position: absolute;
        top: 0;
        left: 0;
        padding: 10px;
        display: grid;
        grid: auto / 15px 1fr;
        align-items: center;
        text-shadow: 0 0 3px #333;
        color: #fff;
        column-gap: 5px;
        font-size: 12px;
        // opacity: 0.9;
        background: linear-gradient(-45deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.05) 100%, );
        border-top-left-radius: 10px;

        .icon {

            width: 15px;
            color: #fff;
        }
    }
}