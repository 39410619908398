@import 'tailwindcss/base';
@import 'tailwindcss/utilities';
// @import 'tailwindcss/components';


:root {
  --primary-color: #F09E7B;
  --secondary-color: #2ecc71;
  --very-dark-gray: #323232;
  --dark-gray: #616161;
  --gray: #848484;
  --light-gray: #9b9b9b;
  --very-light-gray: #e9e9e9;
  --background-color: #ffffff;
  --card-shadow: 0 18px 18px -10px rgba(0, 0, 0, 0.3);
  --border-radius: 20px;
  --std-padding-around: 20px;
  --green-color: #62b254;
  --yellow-color: #f5d30b;
  --red-color: #f05a30;
  --loader-color: #F09E7B;
  --primary-color-faded: rgba(240, 158, 123, 0.9);
  --nav-bar-height: 65px;
}


@font-face {
  font-family: 'TT Norms Pro';
  src: url('./fonts/tt-norms-pro/TT Norms Pro Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'TT Norms Pro';
  src: url('./fonts/tt-norms-pro/TT Norms Pro Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'TT Norms Pro';
  src: url('./fonts/tt-norms-pro/TT Norms Pro Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

body {
  margin: 0;
  font-family: 'TT Norms Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);


}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.title {
  color: var(--dark-gray);
}

h2.header {
  margin: 5px 0;
}

.hasNav {
  height: calc(100dvh - var(--nav-bar-height));
  overflow-y: auto;
}

/* HTML: <div className="loader"></div> */
.loader {
  width: 60px;
  aspect-ratio: 4;
  background: radial-gradient(circle closest-side, var(--loader-color) 90%, #0000) 0/calc(100%/3) 100% space;
  clip-path: inset(0 100% 0 0);
  animation: l1 1s steps(4) infinite;
}

@keyframes l1 {
  to {
    clip-path: inset(0 -34% 0 0)
  }
}