.dateBtn,.leaveBtn, .groupChatBtn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-decoration: none;
    color: #fff;
    
    border-radius: 10px;
    box-sizing: border-box;
    margin-bottom: 10px;
    
    .innerContainer{
        background-color: var(--primary-color);
        width: 100%;
        padding: 10px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    svg {
        height: 20px;
    }
    
    .icon {
        display: flex;
        margin-right: 5px;
    }
    
    .text {
        font-size: 18px;
        font-weight: 900;
    }
}


.leaveBtn {
    padding: 0  ;
    border: none;
    .innerContainer{
        background: none;
        color:var(--red-color);
    }
}

.groupChatBtn{
    .innerContainer{
        background-color:rgba(255 255 255 / 50%);
        color: var(--primary-color);
        border: 1px solid var(--primary-color);
    }

    .notificaitons {
       
       
        margin-left: 4px;
        font-size: 12px;
        background-color: var(--red-color);
        padding: 1px 4px;
        vertical-align: super;
        color: #fff;
        border-radius: 15px;
        display: inline-block;
        margin-bottom: 5px;
        box-sizing: border-box;
        min-width: 16px;
        line-height: 14px;
    }
}