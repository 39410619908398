.date-session {



    .profile-card {
        width: 80vw;
        height: calc(95dvh - 125px);
        background-color: white;
        border-radius: 15px;
        box-shadow: 0 0 15px rgb(255 255 255 / 60%);
        overflow: hidden;
        position: relative;
        border: 5px solid white;
        transition: 2s ease-in-out;
        box-sizing: border-box;
        margin: calc(50px + 4%) auto 5% auto;
        opacity: 1;


        .circular-progress-bar {
            position: absolute;
            right: 0%;
            top: 0%;
            z-index: 2;
        }

    }

    .profile-card.animHide {

        opacity: 0;
    }

    .profile-content {
        text-align: center;

        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: space-around;
        box-sizing: border-box;
    }

    .image-container {
        position: relative;
        height: 100%;
        width: 100%;

        .profile-image {
            height: 100%;
            width: 100%;

            object-fit: cover;

            background-color: var(--very-light-gray);
            filter: saturate(1.5);

            -webkit-filter: blur(0);
            filter: blur(0);
            -webkit-transition: 0.3s ease-in-out;
            transition: 0.3s ease-in-out;

            &.blur {
                filter: blur(25px);
                -webkit-filter: blur(25px);
            }
        }
    }





    .text-middle {
        position: absolute;
        color: #fff;
        width: 100%;
        top: 15%;
        text-align: center;
        text-shadow: 0 0 3px rgba(0, 0, 0, 0.4);

        &.hide {
            display: none;
        }
    }

    .table-number {
        position: absolute;
        color: #fff;
        bottom: calc(50% - 90px);
        right: calc(50% - 64px);
        text-align: center;
        text-shadow: 0 0 10px rgba(0, 0, 0, 0.7);
        font-size: 128px;
        transition: 0.3s;
        width: 128px;

        &.hide {
            display: none;
        }

        &.small {
            bottom: calc(15% + 40px);

            font-size: 35px;
            line-height: 35px;

            padding: 5px;

        }
    }

    .profile-name {
        margin: 0;
        font-size: 24px;
        line-height: 48px;
        color: #ffffff;
        text-shadow: 0 0 10px rgb(0 0 0 / 70%);
        position: absolute;
        left: 25%;
        right: 25%;
        bottom: 15%;
        text-align: center;
    }

    .profile-description {
        background-color: #f9f9f9c9;
        padding: 10px;
        text-align: center;
        height: 15%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        box-sizing: border-box;
        position: absolute;
        bottom: 0;
    }

    .profile-description p {
        margin: 0;
        color: var(--dark-gray);
        font-size: 16px;
    }

    .idNote {
        position: fixed;
        left: 0;
        display: grid;
        place-items: center;
        width: 100%;
        bottom: calc(2% + 50px);
        height: calc(80vh - 50px);
    }

    .id-timer {
        font-size: 35px;
        font-weight: 100;
        color: #fff;
        text-align: center;

    }

    .timer {
        color: white;


        text-align: center;

        position: fixed;
        width: 100%;
        left: 0;
        bottom: calc(50% - 200px);
        text-shadow: 0 0 20px var(--primary-color);
        z-index: 2;

        .time,
        .note {
            font-size: 35px;

            &.to-flashing {
                animation: pulseText 1s ease-in-out 0s infinite alternate;
            }


        }

        .time {
            font-weight: 900;

            &.to-large-text {
                animation: fontSizeIncrease 1s ease-in-out forwards;
            }

            &.to-flashing {
                font-size: 70px;
            }
        }

        .note {
            font-weight: 100;
            line-height: 20vh;
        }


        @keyframes fontSizeIncrease {
            from {
                font-size: 35px;
            }

            to {
                font-size: 70px;
            }
        }



        @keyframes pulseText {
            0% {
                opacity: 0.1;
            }

            100% {
                opacity: 1;
            }
        }
    }



}