.dates-container {
    padding: var(--std-padding-around);
    background-color: #fff;
  
    .list {
      display: flex;
      flex-direction: column;
      
      .loader{
        position: absolute;
        left: calc(50% - 40px);
        top: 50%;
        
      }
    }
    
    .item-container {
      display: flex;
      align-items: center;
      background-color: #f9f9f9;
      border-radius: 10px;
      margin: 10px 0;
      /* padding: 16px; */
      box-shadow: 0 3px 7px 0px rgb(0 0 0 / 10%);
    }
    
    .image {
      width: 50px;
      height: 50px;
      border:1px solid var(--primary-color);
      border-radius: 10px;
      margin: 16px;
      object-fit: cover;
    }
    
    .info-container {
      flex: 1;
    }
    
    .date {
      font-size: 14px;
      color: var(--primary-color);
      margin-bottom: 4px;
    }
    
    .title {
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 4px;
      color: var(--very-dark-gray);
    }
    
    .location {
      font-size: 14px;
      color: #999;
    }

    .no-items {
      font-size: 12px;
      color: var(--dark-gray);
      display: grid;
      place-content: center;
      
      grid: 30px 50px / 150px;
      text-align: center;
      align-items: center;
  }
  }
  
  
  
  