.circular-progress-bar {}

.circular-progress-bar__background {
  fill: none;
  stroke: rgba(230, 230, 230, 0.9);
}

.circular-progress-bar__progress {
  fill: none;
  stroke: #fff;
  transition: stroke-dashoffset 0.35s;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}