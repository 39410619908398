.matches-list {
    width: 100%;
    margin: 0;
    padding: var(--std-padding-around);

    border-radius: 10px;
    box-sizing: border-box;
    height: 100%;
    display: grid;
    grid: repeat(auto-fill, 70px)/ auto;

    .loader {

        position: absolute;
        left: calc(50% - 40px);
        top: 50%;

    }


    .match-item {.matches-list {
        width: 100%;
        margin: 0;
        padding: var(--std-padding-around);
    
        border-radius: 10px;
        box-sizing: border-box;
        height: 100%;
        display: grid;
        grid: repeat(auto-fill, 70px)/ auto;
    
        .loader {
    
            position: absolute;
            left: calc(50% - 40px);
            top: 50%;
    
        }
    
    
        .match-item {
            display: grid;
            // max-height: 50px;
            grid: 1fr / 50px 1fr;
            column-gap: 10px;
    
            padding: 10px;
            border-bottom: 1px solid #eaeaea;
            transition: background-color 0.3s;
            text-decoration: none;
        }
    
    
    
        .profile-pic {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            margin-right: 10px;
            object-fit: cover;
        }
    
        .match-info {
            display: grid;
            grid: auto auto / 1fr;
            place-content: center;
        }
    
        .match-name {
            font-weight: bold;
            font-size: 16px;
            color: var(--very-dark-gray);
        }
    
        .match-message {
            font-size: 14px;
            color: #666;
        }
    
        .match-item.you {
            background-color: var(--primary-color);
            color: #fff;
            /* Optional: adjust text color for contrast */
        }
    
        .match-item.other {
            background-color: var(--other-color);
        }
    
        .no-items {
            color: var(--dark-gray);
            display: grid;
            place-content: center;
    
            grid: 30px 50px / 150px;
            text-align: center;
            align-items: center;
    
            height: 60vh;
        }
    
        .no-items .sign-up-checkbox {
            /* display: none; */
            background: var(--primary-color);
            border: none;
            padding: 10px;
            border-radius: 10px;
            color: #fff;
            display: grid;
            grid: 1fr / 25px 1fr;
    
        }
    
    }
        display: grid;
        // max-height: 50px;
        grid: 1fr / 50px 1fr;
        column-gap: 10px;

        padding: 10px;
        border-bottom: 1px solid #eaeaea;
        transition: background-color 0.3s;
        text-decoration: none;
    }



    .profile-pic {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 10px;
        object-fit: cover;
    }

    .match-info {
        display: grid;
        grid: auto auto / 1fr;
        place-content: center;
    }

    .match-name {
        font-weight: bold;
        font-size: 16px;
        color: var(--very-dark-gray);
    }

    .match-message {
        font-size: 14px;
        color: #666;
    }

    .match-item.you {
        background-color: var(--primary-color);
        color: #fff;
        /* Optional: adjust text color for contrast */
    }

    .match-item.other {
        background-color: var(--other-color);
    }

    .no-items {
        font-size: 12px;
        color: var(--dark-gray);
        place-content: center;

        grid: 30px 50px / 150px;
        text-align: center;
        align-items: center;

        height: 60vh;
    }

    .no-items .sign-up-checkbox {
        /* display: none; */
        background: var(--primary-color);
        border: none;
        padding: 10px;
        border-radius: 10px;
        color: #fff;
        display: grid;
        grid: 1fr / 25px 1fr;

    }

}