.attendees {
    display: flex;
    justify-content: space-between;
    border: 2px solid rgb(240 158 123 / 50%);
    padding: 10px;
    border-radius: 10px;
    margin-bottom: 10px;
    .type {
        display: flex;
        // min-width: 150px;
        justify-content: center;
        align-items: center;
        font-size: 20px;
        color: #f09e7b;
        text-shadow: 0 0 5px white;
        background: rgb(255 255 255 / 80%);
        background: radial-gradient(circle, rgba(255,255,255) 0%, rgba(255,255,255,65%) 100%);
        border-radius: 10px;
    }

    .joinBtn{
        background-color: var(--primary-color);
        padding: 10px;
        border: none;
        border-radius: 10px;
        color: #fff;
        font-size: 18px;
        min-width: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
    
        
        svg {
            width: 20px;
            
        }
        .icon {
            display: flex;
            margin-right: 5px;
        }
        
        .text {
            font-size: 18px;
        }
        
    }

    .joinBtn.wait {
        background-color: #ffb400;
    }
    
    
}





.btnTextTitle {
    margin: 0;
    font-size: 10px;
    text-align: center;
    color: var(--light-gray);
    margin-bottom: 10px;
}