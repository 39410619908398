/* src/components/Popup.scss */

.pop-up {
  .cancelArea {
    position: fixed;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0);
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
  }
  .overlay {
    position: fixed;
    top: 20vh;
    left: 4vw;
    width: 92vw;
    height: 50vh;
    background-color: rgba(255, 255, 255, 0.9);
    border-radius: 10px;
    z-index: 1000;
    
    .content {
      height: inherit;
      display: grid;
      grid: 1fr 1fr/ 1fr;
      place-items: center;
      box-sizing: border-box;
      padding: var(--std-padding-around);
    }

    .toolbar {
      display: grid;
      grid: 1fr / 1fr 35px;
    }
    .close-btn {
      padding: 5px;
      color: var(--primary-color);
      position: absolute;
      right: 0;
      top: 0;
      svg {
        width: 25px;
      }
    }


    .textfieldInput {
      width: 100%;
     
      background-color: rgba(255, 255, 255, 0.7);
      border: 1px solid var(--very-light-gray);
      border-radius: 15px;
      padding: var(--std-padding-around);
      box-sizing: border-box;
      font-size: 14px;
      color:var(--very-dark-gray)
    }
  }

}
