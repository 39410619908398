/* Carousel.scss */
.carousel {
    position: relative;
    width: 100%;
    margin: auto;
    overflow: hidden;
    height: 100%;


    .carousel-inner {
        display: flex;
        transition: transform 0.5s ease-in-out;
        height: 100%;
      }
      
      .carousel-item {
        min-width: 100%;
        transition: opacity 0.5s ease-in-out;
      }
      
      .carousel-item img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        display: block;
        filter: sepia(0.2)  saturate(2);
      }
      
      .carousel-indicators {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        display: flex;
        gap: 7px;
      }
      
      .indicator {
        width: 7px;
        height: 7px;
        background-color: #ccc;
        border-radius: 50%;
        cursor: pointer;
      }
      
      .indicator.active {
        background-color: #fff;
      }
      
      .carousel-control {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        background-color: rgba(0, 0, 0, 0.5);
        border: none;
        color: white;
        font-size: 24px;
        padding: 10px;
        cursor: pointer;
        z-index: 1;
        display: none;
      }
      
      .carousel-control.prev {
        left: 10px;
      }
      
      .carousel-control.next {
        right: 10px;
      }
  }
  
  