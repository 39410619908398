.event-details-container {
  // font-family: 'TT Norms Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  /* padding: var(--std-padding-around); */
  margin: auto;
  overflow: hidden;

  .location,
  .title {
    text-overflow: ellipsis;
    text-wrap: nowrap;
    overflow: hidden;
  }

  .location, .second-line {
    color: var(--gray);
    font-size: 13px;
    line-height: 20px;
    display: flex;


   
  }
  .second-line{
    margin-left:10px;
  }

  .title {
    font-size: 18px;
    margin: 0;
    color: var(--very-dark-gray);
  }

  .date-age{
    display: flex;
    justify-content: space-between;
  }

  .age {
    color: var(--dark-gray);
    font-size: 14px;
    display: flex;

  }

  .back-button {
    position: fixed;
    z-index: 2;
    top: calc(env(safe-area-inset-top) + var(--std-padding-around) + 5px);
    left: calc(var(--std-padding-around) + 5px);

    color: var(--dark-gray);
    cursor: pointer;
    background-color: rgb(255, 255, 255, 0.5);
    background: radial-gradient(circle,
        rgba(255, 255, 255, 100%) 0%,
        rgba(255, 255, 255, 20%) 100%);

    border-radius: 50px;

    width: 75px;
    height: 40px;
    box-sizing: border-box;

    display: flex;
    padding: 7px;
    text-shadow: 0 0 5px #fff;

    .backTxt {
      line-height: 24px;
    }
  }

  .image-container {

    height: 40vh;
    overflow: hidden;
    display: flex;

    img {
      object-fit: cover;
      width: 100%;
      object-position: center;
    }
  }

  .content {
    padding: var(--std-padding-around);

    // svg {
    //   width: 14px;
    //   margin-right: 5px;
    // }
  }

  .date-time {
    color: var(--primary-color);
    font-size: 16px;
    display: flex;

  }


  .map-container {
    width: 100%;

    background-image: url('https://maps.googleapis.com/maps/api/staticmap?center=San+Francisco,CA&zoom=12&size=400x200');
    background-size: cover;
    background-position: center;
    margin-bottom: 20px;
    border-radius: 10px;
    overflow: hidden;
  }

  .description {
    margin-bottom: 200px;
    color: var(--light-gray);
  }

  .buttons {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: var(--std-padding-around);
    box-sizing: border-box;
    padding-bottom: env(safe-area-inset-bottom);

  }

  .men-chat-button,
  .match-button {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }

  .men-chat-button:hover,
  .match-button:hover {
    background-color: var(--primary-color);
  }

}